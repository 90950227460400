import { NavLink, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react'
import classNames from '../../utils/utils';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useSelector } from 'react-redux';
import Logo from '../../pages/MainPage/img/logoColor.svg';

const clas = "flex flex-grow min-w-[190px] flex-col min-h-screen h-full border-r border-gray-200 bg-white pb-4 "

export default function Example({ newClass, onClick }) {
  const locationEmployee = useLocation().pathname.startsWith("/Employee");
  const locationClient = useLocation().pathname.startsWith("/Client");
  const locationSuperAdmin = useLocation().pathname.startsWith("/SuperAdmin");
  const employeeData = useSelector(state => state?.adminProfile?.employeeProfiledata)
  const navigation = 
  locationEmployee ? [
    {
      name: 'Dashboard',
      icon: "/dashboardIcon.svg",
      icon_white: '/dashboardIconWhite.svg',
      href: 'Dashboard'
    },
    {
      name: 'Service Log',
      icon: '/Layer_2.svg',
      icon_white: '/ServiceLogIconWhite.svg',
      href: 'ServiceLog',
      children: employeeData?.type ==1 && employeeData?.is_both==0 ? [
        {
          name: 'Service Log',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'UnbillableHours',
        },
      ]
      : [
        {
          name: 'Service Log',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'ServiceLog',
        },
        {
          name: 'Non-Service Hours',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'UnbillableHours',
        },

      ],
    },
    {
      name: 'Company Forms',
      icon: '/CompanyFormsImage.svg',
      icon_white: '/CompanyIconWhite.svg',
      href: 'CompanyForms',
    },
  ]
    : locationClient ?
      [
        {
          name: 'Service Log',
          icon: '/Layer_2.svg',
          icon_white: '/ServiceLogIconWhite.svg',
          href: 'ServiceLog',
        },
        {
          name: 'Invoices',
          icon: '/InvoicesImage.svg',
          icon_white: '/InvoiceIconWhite.svg',
          href: 'Invoices',
        },
          {
            name: 'Forms',
            icon: '/CompanyFormsImage.svg',
            icon_white: '/CompanyIconWhite.svg',
            href: 'CompanyForms',
          },
      ]
      : locationSuperAdmin ?
        [
          {
            name: 'Dashboard',
            icon: "/dashboardIcon.svg",
            icon_white: '/dashboardIconWhite.svg',
            href: 'Dashboard'
          },
          {
            name: 'Clients',
            icon: '/Layer_1.svg',
            icon_white: '/Layer_1_White.svg',
            href: 'Clients',
          },
        ]
        :
        [
          {
            name: 'Dashboard',
            icon: "/dashboardIcon.svg",
            icon_white: '/dashboardIconWhite.svg',
            href: 'Dashboard'
          },

          {
            name: 'Employees',
            icon: '/EmployeesIcon.svg',
            icon_white: '/EmployeesIconWhite.svg',
            href: 'Employees',
            children: [
              {
                name: 'Employees',
                icon: "/EmployeesIcon.svg",
                icon_white: '/EmployeesIconWhite.svg',
                href: 'Employees',
              },
              {
                name: 'Employee Actions',
                icon: "/EmployeesIcon.svg",
                icon_white: '/EmployeesIconWhite.svg',
                href: 'EmployeeActions',
              },

            ],
          },
          {
            name: 'Clients',
            icon: '/Layer_1.svg',
            icon_white: '/Layer_1_White.svg',
            href: 'Clients',
          },
          {
            name: 'Services',
            icon: '/Union1.svg',
            icon_white: '/ServicesIconWhite.svg',
            href: 'Services'
          },
          {
            name: 'Service Log',
            icon: '/Layer_2.svg',
            icon_white: '/ServiceLogIconWhite.svg',
            href: 'ServiceLog',
            children: [
              {
                name: 'Service Log',
                icon: '/Layer_2.svg',
                icon_white: '/ServiceLogIconWhite.svg',
                href: 'ServiceLog',
              },
              {
                name: 'Non-Service Hours',
                icon: '/Layer_2.svg',
                icon_white: '/ServiceLogIconWhite.svg',
                href: 'UnbillableHours',
              },

            ],
          },

          {
            name: 'Invoices',
            icon: '/InvoicesImage.svg',
            icon_white: '/InvoiceIconWhite.svg',
            href: 'Invoices',
            children: [
              {
                name: 'Invoices',
                icon: '/InvoicesImage.svg',
                icon_white: '/InvoiceIconWhite.svg',
                href: 'Invoices',
              },
              {
                name: 'Payments',
                icon: '/InvoicesImage.svg',
                icon_white: '/InvoiceIconWhite.svg',
                href: 'Payments',
              },
            ],
          },
          {
            name: 'Company Forms',
            icon: '/CompanyFormsImage.svg',
            icon_white: '/CompanyIconWhite.svg',
            href: 'CompanyForms',
            children: [
              {
                name: 'Company Form Employee',
                icon: "/CompanyFormsImage.svg",
                icon_white: '/CompanyIconWhite.svg',
                href: 'CompanyForms',
              },
              {
                name: 'Company Form Client',
                icon: "/CompanyFormsImage.svg",
                icon_white: '/CompanyIconWhite.svg',
                href: 'CompanyFormsClients',
              },
            ],
          },
          {
            name: 'Reports',
            icon: '/filled1.svg',
            icon_white: '/ReportIconWhite.svg',
            href: 'Reports',
          },
          {
            name: 'Requests',
            icon: '/CompanyFormsImage.svg',
            icon_white: '/CompanyIconWhite.svg',
            href: 'CompanyForms',
            children: [
              {
                name: 'Requests',
                icon: "/CompanyFormsImage.svg",
                icon_white: '/CompanyIconWhite.svg',
                href: 'Requests',
              },
              {
                name: 'Request Forms',
                icon: "/CompanyFormsImage.svg",
                icon_white: '/CompanyIconWhite.svg',
                href: 'CompanyFormsClients',
              },
            ],
          },
          {
            name: 'Project/Cases',
            icon: '/filled1.svg',
            icon_white: '/ReportIconWhite.svg',
            href: 'Cases',
          },

        ]

  return (
    <div className={clas + newClass}>
      <div className="relative z-10 flex py-2 lg:px-0">
                  <div  className="flex items-center cursor-pointer ml-[20px]">
                    <img
                      // className="sm:h-[52px] h-[40px]"
                      className="sm:h-[53px] h-[28px]"
                      src={Logo}
                      alt="Your Company"
                    />
                  </div>
                </div>
      <div className="flex flex-grow flex-col">
        <nav className="flex-1 space-y-1 bg-white px-2" aria-label="Sidebar">
          <ul>
            {navigation.map((item) =>
              !item.children ?
                <NavLink
                  key={item.name}
                  to={item?.href}
                  onClick={onClick}
                  style={{ '--image-url': `url(${item.icon})`, '--image-url1': `url(${item.icon_white})` }}
                  className={({ isActive }) =>  (isActive ? "bg-[#344C8C] font-['Poppins'] mt-6 font-bold text-[#FFFFFF] group flex w-full items-center rounded-md py-2 pl-2 text-sm [&>div>div]:bg-[image:var(--image-url1)] font-bold"
                    : 'bg-white text-[#344C8C] hover:bg-gray-50 [&>div>div]:bg-[image:var(--image-url)] bg-no-repeat  font-["Poppins"] mt-6  hover:text-gray-900 group flex w-full items-center rounded-md py-2 pl-2 text-sm font-normal hover:bg-gray-50'
                  )}
                >
                  <div className={`flex gap-4`}>
                    <div className='w-6 h-6 bg-no-repeat'></div>
                    {item.count ?
                     <li className='flex gap-1' >{item.name} <span className='text-[#FFFFFF] p-1 bg-[#FF6B00] text-[8px] block w-4 h-4 flex items-center float-right rounded-full justify-center'>{item.count}</span></li> : <li className='text-base font-poppins'>{item.name}</li>}
                  </div>
                </NavLink>
                :
                <Disclosure as="div" key={item.name}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        style={{ '--image-url': `url(${item.icon})`, '--image-url1': `url(${item.icon_white})` }}
                        className={classNames(
                          item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                          'bg-white text-[#344C8C] hover:bg-gray-50 [&>div>div]:bg-[image:var(--image-url)] bg-no-repeat  font-["Poppins"] mt-6  hover:text-gray-900 group flex w-full items-center rounded-md py-2 pl-2 text-sm font-medium hover:bg-gray-50'
                        )}
                      >

                        <div className='flex gap-4'>
                          <div className='w-6 h-6 bg-no-repeat'></div>
                          <li className='flex gap-1 text-base font-poppins' >
                            {item.name}
                          </li>
                        </div>
                        {/* </NavLink> */}
                        <ChevronRightIcon
                          className={classNames(
                            open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                            'ml-auto h-5 w-5 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel as="ul" className="mt-1 px-2">
                        {item.children.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item?.href}
                            onClick={onClick}
                            style={{ '--image-url': `url(${item.icon})`, '--image-url1': `url(${item.icon_white})` }}
                            className={({ isActive }) => (isActive ? "bg-[#344C8C] font-['Poppins'] mt-6 font-bold text-[#FFFFFF] group flex w-full items-center rounded-md py-2 pl-2 text-sm [&>div>div]:bg-[image:var(--image-url1)] font-bold  "
                              : 
                              'bg-white text-[#344C8C] hover:bg-gray-50 [&>div>div]:bg-[image:var(--image-url)] bg-no-repeat  font-["Poppins"] mt-6  hover:text-gray-900 group flex w-full items-center rounded-md py-2 pl-2 text-sm font-normal hover:bg-gray-50 '
                            )}
                          >
                            <div className='flex gap-3 items-center'>
                              <div className='w-6 h-6 bg-no-repeat'></div>
                              {item.count ? <li className='flex gap-1' >{item.name} <span className='text-[#FFFFFF] p-1 bg-[#FF6B00] text-[8px] block w-4 h-4 flex items-center float-right rounded-full justify-center'>{item.count}</span></li> : <li>{item.name}</li>}
                            </div>
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
            )}
          </ul>
        </nav>
      </div>
    </div>
  )
}
