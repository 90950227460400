import { useEffect, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { notifySuccess } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import InputLabel from '../../components/InputLabel/InputLabel';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import RequestErrorCreateCompanyForm from './RequestErrorCreateCompanyForm';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import { Widget } from '@uploadcare/react-widget';
import { useDropzone } from 'react-dropzone';

export default function CreateCompanyFormNew() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    // defaultValues: useMemo(() => CreateInvoiceDefaultValue(), []),
    resolver: yupResolver(RequestErrorCreateCompanyForm()),
  });
  const navigate = useNavigate();

  const [allEmployeesData, setAllEmployeesData] = useState([]);

 

  useEffect(() => {
    EmployeesHttp.employeesFullinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Employees' });
      setAllEmployeesData(employesList);
    });
  }, []);

  const afterSubmit = () => {
    notifySuccess('Company Form successfully created');
    setTimeout(() => {
      navigate('/Admin/CompanyForms');
    }, 2000);
  };

  const onSubmit = async data => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', acceptedFiles?.[0]);
    data.file = formData.get('file');
    companyFormsHttp
      ?.createCompanyForms(data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
        setIsLoading(false);
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/CompanyForms');
  };

  const { acceptedFiles, getRootProps, getInputProps, FileRejection } = useDropzone();

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  console.log(acceptedFiles, 'acceptedFiles');
  console.log(getRootProps(), 'getRootProps');
  console.log(getInputProps(), 'getInputProps');
  console.log(FileRejection, 'FileRejection');

  return (
    <div className="sm:px-8 px-2 py-8 w-full ">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Create Company Form'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 items-end">
              <div className="mt-5">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Form Title'}
                  placeholder={'Details'}
                  register={register}
                  name={`title`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.title?.message}</p>}
              </div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Employee'}
                  selectData={allEmployeesData}
                  selectClass="flex-col gap-2"
                  name={'employees'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employees?.message}</p>}
              </div>
            </div>

            <section className="container mt-5">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input
                  {...getInputProps()}
                  class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="file_input_help"
                  id="file_input"
                  type="file"
                  style={{ display: 'block' }}
                />
              </div>
            </section>
          </div>

          <div className="mt-40 z-50">
            <CancleAdd disabled={isLoading} buttonText={`Send`} onClickCancle={OnClickCancle} />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
