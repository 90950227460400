import { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import InvoiceHttp from '../../http/invoiceHttp/invoiceHttp';
import TableClients from '../../components/DataTable/TableClients';
import Pagination from '../../components/Pagination/Pagination';
import { columnsInvoiceAdmin } from './helpers/ExportInvoicesUtils';

import 'react-toastify/dist/ReactToastify.css';

export default function ExportInvoices() {
  const [error, setError] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [dataExport, setDataExport] = useState();
  const [dataTable, setDataTable] = useState();

  const navigate = useNavigate();
  const onSubmit = e => {
    e.preventDefault();
  };
  const axiosData = async () => {
    try {
      const response = await InvoiceHttp.exportInvoices(dateRange);
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      setDataExport({ url, filename: `${dateRange?.dataMonth}_${dateRange?.dataYear} Invoice database copy.csv` });
    } catch (error) {
      setError(error?.response?.data?.error);
    }
  };
  const OnClickCancle = () => {
    navigate('/Admin/Invoices');
  };
  const handleDateChange = value => {
    setDataExport(null);
    setError('');
    const date = {
      dataMonth: value?.format('MM'),
      dataYear: value?.format('YYYY'),
    };
    setDateRange(date);
  };

  useEffect(() => {
    axiosData();
    dateRange && InvoiceHttp.getInvoicesByDate(dateRange).then(e => setDataTable(e.data));
  }, [dateRange]);

  const onPaginationClick = id => {
    InvoiceHttp.getInvoicesByDate(dateRange, id).then(e => {
      setDataTable(e?.data);
    });
  };
  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Export Invoices'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={onSubmit} className="w-full">
          <div className="py-4 sm:px-7 px-2 w-full flex flex-col gap-2">
            <p className="text-[#60657B] opacity-50 text-xs">{'Date Range'}</p>
            <div className="w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[38px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none">
              <DatePicker
                format="MM/YYYY"
                placeholder="MM/YYYY"
                onChange={handleDateChange}
                onlyMonthPicker
                dateFormat="MM/yyyy"
              />
            </div>
            {error && <p className="mb-0 text-sm text-amber-800">{error}</p>}
          </div>
          <div>
            <CancleAdd
              disabled={!dateRange || error}
              buttonText={
               
                <a href={dataExport?.url} download={dataExport?.filename}>
                  Export Invoices
                </a>
              }
              onClickCancle={OnClickCancle}
            />
          </div>
        </form>
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="w-full overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableClients tbody={dataTable?.data ?? []} columns={columnsInvoiceAdmin} maxHeight="600px" />
            </div>
          </div>
          <Pagination onPaginationClick={onPaginationClick} paginationData={dataTable?.links} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
