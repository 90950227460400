import { Fragment, } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Button from '../Button/Button'



export default function InvoiceModal({ open, setOpen, fileUrl, onClickSend, sendable, }) {

    const onClickClose = () => setOpen(false)

    console.log(fileUrl?.url,"fileUrl?.url");
    

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[50rem] sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClickClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start justify-center">
                                </div>
                                <embed src={`${fileUrl?.url}#toolbar=0&navpanes=0&scrollbar=0`} type='application/pdf' width="98%" height={sendable ? "800px" : "600px"} frameBorder="0" />
                                {fileUrl && sendable && (
                                    <div className="mt-5" >
                                        <div className="flex justify-end items-center">
                                            <a href={fileUrl.url} download={fileUrl.filename}>
                                                Download PDF
                                            </a>
                                            {onClickSend && <Button buttonText={fileUrl?.is_send ? "Resend" : "Send"} className="ml-5 bg-[#31D78D] text-white" type="button" onClick={() => onClickSend(fileUrl.id,fileUrl?.page)} />}
                                        </div>
                                    </div>

                                )}


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
