import { $authHost, $authHostt } from "..";

export default class adminUnbillableHoursHttp {
    static serviceLogAddLinks(data) {
        return $authHost.post(`/employee/serviceLog`, data)
    };
    static serviceLogDataLinks(dateRange, id,dataEmployeeCurrent,page) {
        return $authHost.get(`/admin/ubillableServiceLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&employee_id=${dataEmployeeCurrent ?? ""}&page=${page ?? ""}`)
    };
    static serviceLogDataLinksId(id) {
        return $authHost.get(`/admin/ubillableServiceLog?page=${id}`)
    };
    static serviceLogClientsLinks() {
        return $authHost.get(`/employee/get/clients`)
    };
    static serviceLogServicesLinks(id) {
        return $authHost.get(`/employee/get/services/${id}`)
    };
    static serviceLogPositionsLinks(id) {
        return $authHost.get(`/employee/get/positions/${id}`)
    };
    static serviceLogWeekLinks(id) {
        return $authHost.get(`/employee/serviceLog/week/${id}`)
    };
    static currentserviceLogLinks(id) {
        return $authHost.get(`/employee/serviceLog/${id}`)
    };
    static currentUbillableServiceLogLinks(id) {
        return $authHost.get(`/admin/ubillableServiceLog/${id}`)
    };

    static serviceLogDeferenceLinks(data) {
        return $authHost.post(`/employee/distance`, data)
    };
    static serviceLogAdminDataLinksId(id) {
        return $authHost.get(`/admin/serviceLog?page=${id}`)
    };
    static serviceLogSendGetListLogs(client_id) {
        return $authHost.get(`/admin/serviceLogByClient/${client_id}`)
    };
    static serviceLogWeekAdminLinks(id,employeId) {
        return $authHost.get(`/admin/ubillableServiceLog/week/${id}/employee/${employeId}`)
    };
    static serviceLogWeekAdminPDFLinks(id,employeId,clientId) {
        return $authHostt.get(`/admin/pdf/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static serviceLogWeekAdminSendLinks(id,employeId,clientId) {
        return $authHostt.post(`/admin/send/pdf/week/${id}/employee/${employeId}/client/${clientId}`)
    };
    static serviceLogRemaindAdminLinks(id,employeId) {
        return $authHost.post(`/admin/remind/week/${id}/employee/${employeId}`)
    };
    static serviceLogDiscardAdminLinks(id,employeId) {
        return $authHost.post(`/admin/ubillableServiceLog/discard/week/${id}/employee/${employeId}`)
    };
    static serviceLogApporveAdminLinks(id,employeId) {
        return $authHost.post(`/admin/ubillableServiceLog/approve/week/${id}/employee/${employeId}`)
    };
    static updateCurrentUbillableServiceLogLinks(id,data) {
        return $authHost.post(`/admin/update/ubillableServiceLog/${id}`,data)
    };
    static deleteCurrentubillableServiceLogLinks(id) {
        return $authHost.delete(`/admin/removeUnbillableLog/${id}`)
    };
}